exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog-post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-2018-the-review-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2018-the-review/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-2018-the-review-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-2019-chrome-developer-summit-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2019-chrome-developer-summit/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-2019-chrome-developer-summit-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-2019-the-review-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2019-the-review/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-2019-the-review-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-2020-the-review-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2020-the-review/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-2020-the-review-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-2021-in-review-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2021-in-review/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-2021-in-review-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-after-authentication-git-hub-google-magic-links-and-pass-key-authentication-in-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/after-authentication-git-hub-google-magic-links-and-pass-key-authentication-in/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-after-authentication-git-hub-google-magic-links-and-pass-key-authentication-in-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-answer-to-an-honest-question-about-learning-tech-in-nigeria-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/answer-to-an-honest-question-about-learning-tech-in-nigeria/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-answer-to-an-honest-question-about-learning-tech-in-nigeria-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-arrayflat-and-arrayflatmap-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/arrayflat-and-arrayflatmap/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-arrayflat-and-arrayflatmap-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-beginnings-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/beginnings/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-beginnings-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-building-an-e-commerce-app-continued-authentication-part-3-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/building-an-e-commerce-app-continued-authentication-part-3/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-building-an-e-commerce-app-continued-authentication-part-3-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-classes-in-java-script-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/classes-in-java-script/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-classes-in-java-script-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-code-like-introvert-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/code-like-introvert/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-code-like-introvert-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-developer-experience-with-command-line-interface-cli-tools-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/developer-experience-with-command-line-interface-cli-tools/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-developer-experience-with-command-line-interface-cli-tools-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-generators-in-java-script-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/generators-in-java-script/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-generators-in-java-script-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-git-with-hub-like-github-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/git-with-hub-like-github/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-git-with-hub-like-github-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-git-with-hub-like-github-part-2-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/git-with-hub-like-github-part-2/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-git-with-hub-like-github-part-2-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-hello-world-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/hello-world/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-hello-world-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-introduction-to-data-structures-implementing-linked-list-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/introduction-to-data-structures-implementing-linked-list/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-introduction-to-data-structures-implementing-linked-list-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-introductory-guide-to-es-2021-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/introductory-guide-to-es-2021/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-introductory-guide-to-es-2021-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-learn-javascript-properly-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/learn-javascript-properly/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-learn-javascript-properly-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-lesson-learnt-meeting-my-mentor-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/lesson-learnt-meeting-my-mentor/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-lesson-learnt-meeting-my-mentor-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-mentorship-series-kolade-will-not-just-give-up-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/mentorship-series-kolade-will-not-just-give-up/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-mentorship-series-kolade-will-not-just-give-up-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-migrated-my-blog-from-md-to-mdx-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/migrated-my-blog-from-md-to-mdx/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-migrated-my-blog-from-md-to-mdx-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-migrating-a-react-code-base-test-from-using-ts-jest-to-babel-jest-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/migrating-a-react-code-base-test-from-using-ts-jest-to-babel-jest/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-migrating-a-react-code-base-test-from-using-ts-jest-to-babel-jest-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-object-properties-configuration-in-java-script-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/object-properties-configuration-in-java-script/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-object-properties-configuration-in-java-script-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-prototypes-and-inheritance-in-java-script-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/prototypes-and-inheritance-in-java-script/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-prototypes-and-inheritance-in-java-script-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-re-export-syntax-is-delicious-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/re-export-syntax-is-delicious/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-re-export-syntax-is-delicious-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-scheduling-with-timeout-and-interval-decorators-forwarding-call-apply-and-function-binding-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/scheduling-with-timeout-and-interval-decorators-forwarding-call-apply-and-function-binding/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-scheduling-with-timeout-and-interval-decorators-forwarding-call-apply-and-function-binding-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-setting-up-for-the-forgot-password-and-reset-password-flow-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/setting-up-for-the-forgot-password-and-reset-password-flow/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-setting-up-for-the-forgot-password-and-reset-password-flow-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-story-part-1-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/story-part-1/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-story-part-1-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-summary-report-of-the-freecodecamp-ibadan-community-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/summary-report-of-the-freecodecamp-ibadan-community/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-summary-report-of-the-freecodecamp-ibadan-community-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-why-you-should-learn-javascript-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/why-you-should-learn-javascript/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-why-you-should-learn-javascript-index-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-content-blog-writing-test-for-the-vitejs-react-app-starter-index-mdx": () => import("./../../../src/pages/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/writing-test-for-the-vitejs-react-app-starter/index.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-content-blog-writing-test-for-the-vitejs-react-app-starter-index-mdx" */),
  "component---src-pages-contact-index-mdx": () => import("./../../../src/pages/contact/index.mdx" /* webpackChunkName: "component---src-pages-contact-index-mdx" */),
  "component---src-pages-contact-success-mdx": () => import("./../../../src/pages/contact/success.mdx" /* webpackChunkName: "component---src-pages-contact-success-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-template-js": () => import("./../../../src/pages/portfolio-template.js" /* webpackChunkName: "component---src-pages-portfolio-template-js" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-bejamas-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/bejamas/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-bejamas-index-mdx" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-chooselife-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/chooselife/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-chooselife-index-mdx" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-cova-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/cova/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-cova-index-mdx" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-equilibra-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/equilibra/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-equilibra-index-mdx" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-groovy-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/groovy/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-groovy-index-mdx" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-talentql-test-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/talentql-test/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-talentql-test-index-mdx" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-virtual-closet-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/virtual-closet/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-virtual-closet-index-mdx" */),
  "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-zeedas-index-mdx": () => import("./../../../src/pages/portfolio-template.js?__contentFilePath=/opt/build/repo/content/portfolio/zeedas/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-template-js-content-file-path-content-portfolio-zeedas-index-mdx" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */)
}

